import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ContentEditable from "react-contenteditable";
import "./Modal.css";

export default function CustomModal({
  openModal,
  closeModal,
  data,
  show,
  typeOfModal,
  global,
}) {
  const jsonfile = require("jsonfile");
  const file = "./data/test.json";
  const fs = require("fs");
  function handleCloseModal() {
    closeModal();
  }
  useEffect(() => {
    // console.log(data.things_to_ask)
  }, [data]);

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={handleCloseModal}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {typeOfModal}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data
            ? data.things_to_ask.map((item, index) => {
                return (
                  <div
                    className="backgroundColorForContentEditable my-2"
                    key={index}
                  >
                    <ContentEditable
                      className="textCenter"
                      html={item}
                      disabled={false}
                      onChange={(e) => {
                        data[index] = e.target.value;
                      }}
                    />
                  </div>
                );
              })
            : null}
        </Modal.Body>
      </Modal>
    </>
  );
}
