import { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import "./CustomTable.css";
import jsonData from "./data/data.json";
import Button from "react-bootstrap/Button";
import CustomModal from "./Modal";
import Select from "react-select";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
export default function ResponsiveBreakpointsExample() {
  const [tableData, setTableData] = useState("");
  const [modalState, setModalState] = useState(false);
  const [currentIndexSelected, setCurrentIndexSelected] = useState(-1);
  const [tags, setTags] = useState([]);
  const [defaultTableValue, setDefaultTableValue] = useState("");
  const [searchNames, setSearchNames] = useState("");
  const [prevTableState, setPrevTableState] = useState([]);
  const [currentSelectedTags, setCurrentSelectedTags] = useState([]);
  function showModal(index) {
    setCurrentIndexSelected(index);
    setModalState(true);
  }
  function closeModal() {
    setModalState(false);
  }

  function getTagsAndLeetcodeTitleNames() {
    let currentTags = [];
    let seen = new Set();
    let currentLeetcodeNames = [];
    let seenNames = new Set();
    for (let i = 0; i < tableData.data.length; i++) {
      let leetcodeTitle = tableData.data[i].leetcode_link_title;
      if (!seenNames.has(leetcodeTitle)) {
        currentLeetcodeNames.push({
          value: leetcodeTitle,
          label: leetcodeTitle,
        });
        seenNames.add(leetcodeTitle);
      }
      for (let j = 0; j < tableData.data[i].leetcode_tags.length; j++) {
        let currentTag = tableData.data[i].leetcode_tags[j];
        if (!seen.has(currentTag)) {
          let obj = { value: currentTag, label: currentTag };
          currentTags.push(obj);
          seen.add(currentTag);
        }
      }
    }
    return [currentTags, currentLeetcodeNames];
  }

  function handleSelectFilterBySearchName(selectedNames) {
    if (selectedNames == null || selectedNames.length == 0) {
      setTableData(defaultTableValue);
      return;
    }
    let temp = new Set();
    for (let row = 0; row < tableData.data.length; row++) {
      let leetcodeTitle = tableData.data[row].leetcode_link_title;
      for (let i = 0; i < selectedNames.length; i++) {
        if (leetcodeTitle === selectedNames[i].value) {
          temp.add(tableData.data[row]);
        }
      }
    }
    let obj = { data: [...temp] };

    window.open(obj.data[0].solution_link, "_blank");
    setTableData(obj);
  }
  function handleSelectFilterBySearchTags(selectedTags) {
    if (selectedTags == null || selectedTags.length == 0) {
      setTableData(defaultTableValue);
      return;
    }
    let temp = new Set();

    for (let row = 0; row < tableData.data.length; row++) {
      let currentSelectedTags = tableData.data[row].leetcode_tags;
      let count = 0;
      for (let i = 0; i < selectedTags.length; i++) {
        if (currentSelectedTags.includes(selectedTags[i].value)) {
          count++;
        }
      }
      if (count === selectedTags.length) {
        temp.add(tableData.data[row]);
      }
    }
    let copy = [...prevTableState];
    let obj = { data: [...temp] };
    setCurrentSelectedTags(selectedTags);
    copy.push(obj);
    setPrevTableState(copy);
    setTableData(obj);
  }
  useEffect(() => {
    alert("use tab and shift tab to move silently in searching");
    let temp = loadData();
    setTableData(temp);
    setDefaultTableValue(temp);
  }, []);
  useEffect(() => {
    console.log("prev table state", prevTableState);
  }, [prevTableState]);
  useEffect(() => {
    console.log(currentSelectedTags);
  }, [currentSelectedTags]);

  useEffect(() => {
    console.log("table data", tableData);
    if (tableData !== "") {
      // debugger;
      let temp = getTagsAndLeetcodeTitleNames();
      setTags(temp[0]);
      setSearchNames(temp[1]);
    }
  }, [tableData]);

  useEffect(() => {
    // console.log(tags);
  }, [tags]);

  const loadData = () => {
    return JSON.parse(JSON.stringify(jsonData));
  };
  function cacheSolutionsFirst20() {
    for (let i = 0; i < parseInt(tableData.data.length/4)*1; i++) {
      let solutionLink = tableData.data[i].solution_link;
      window.open(solutionLink, "_blank");
    }
  }
  function cacheSolutionsFirst20to40() {
    for (let i = parseInt(tableData.data.length/4)*1; i < parseInt(tableData.data.length/4)*2; i++) {
      debugger;
      let solutionLink = tableData.data[i].solution_link;
      window.open(solutionLink, "_blank");
    }
  }
  function cacheSolutionsFirst40to60() {
    for (let i = parseInt(tableData.data.length/4)*2; i < parseInt(tableData.data.length/4)*3; i++) {
      let solutionLink = tableData.data[i].solution_link;
      window.open(solutionLink, "_blank");
    }
  }
  function cacheSolutionsFirst60toEnd() {
    for (let i = parseInt(tableData.data.length/4)*3; i < tableData.data.length; i++) {
      let solutionLink = tableData.data[i].solution_link;
      window.open(solutionLink, "_blank");
    }
  }
  return (
    <>
  
      <Container className="containerSelectStyle">
        <Row>
          <Col>
            <Select
              options={tags}
              menuPlacement="top"
              isMulti
              className="basic-multi-select bringFront selectStyle"
              classNamePrefix="select technologies"
              closeMenuOnSelect={false}
              maxMenuHeight={600}
              autoComplete ="yes"
              placeholder="Filter by tags"
              onChange={(selectedTags) => {
                if (selectedTags == null || selectedTags.length === 0) {
                  setTableData(defaultTableValue);
                  return;
                }
                if (
                  prevTableState.length > 0 &&
                  currentSelectedTags.length > selectedTags.length
                ) {
                  let copy = [...prevTableState];
                  copy.pop();
                  setPrevTableState(copy);
                  setTableData(copy[copy.length - 1]);
                  setCurrentSelectedTags(selectedTags);
                  return;
                }
                handleSelectFilterBySearchTags(selectedTags);
              }}
            />
          </Col>
          <Col>
            <Select
              options={searchNames}
              menuPlacement="top"
              isMulti
              // aria-autocomplete = "list"
              // autoComplete ="yes"
              className="basic-multi-select bringFront selectStyle"
              classNamePrefix="select technologies"
              closeMenuOnSelect={false}
              maxMenuHeight={600}
              placeholder="Filter by Name"
              onChange={(selectedNames) => {
                if (selectedNames == null || selectedNames.length === 0) {
                  setTableData(defaultTableValue);
                  return;
                }

                handleSelectFilterBySearchName(selectedNames);
              }}
            />
          </Col>
        </Row>
      </Container>
      {tableData !== "" ? (
        <>
          <Container>
            <Row>
              <Col>
                <Button onClick={cacheSolutionsFirst20}>
                  cache 1/4
                </Button>
              </Col>
              <Col>
                <Button onClick={cacheSolutionsFirst20to40}>
                  cache 2/4
                </Button>
              </Col>
              <Col>
                <Button onClick={cacheSolutionsFirst40to60}>
                  cache 3/4
                </Button>
              </Col>
              <Col>
                <Button onClick={cacheSolutionsFirst60toEnd}>
                  cache 4/4
                </Button>
              </Col>
            </Row>
          </Container>
        </>
      ) : null}
      {tableData && tableData.data && tableData.data.length > 0 ? (
        <div className="customcontainer overflow-scroll">
          <CustomModal
            openModal={showModal}
            closeModal={closeModal}
            data={tableData.data[currentIndexSelected]}
            show={modalState}
            typeOfModal="Questions"
          />
          <Table
            variant="dark"
            className="content"
            style={{ width: "1500px" }}
            responsive="lg"
            data-sticky-header={true}
          >
            <thead>
              <tr>
                <th className="headerFontColor">Row Number</th>
                <th
                  className="headerFontColor"
                  key={tableData.data[0].leetcode_tags}
                >
                  Tags
                </th>
                <th
                  className="headerFontColor"
                  key={tableData.data[0].leetcode_link}
                >
                  Leetcode Link
                </th>
                {/* <th className="headerFontColor" key={tableData.data[0].things_to_ask}>Questions to Ask</th> */}
                <th
                  className="headerFontColor"
                  key={tableData.data[0].solution_link}
                >
                  Solution
                </th>

                {/* <th className="headerFontColor" key={tableData.data[0].testcases}>Testcases</th> */}
              </tr>
            </thead>
            <tbody>
              {/* iterate through tableData
               */}

              {tableData.data.map((row, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    {row.leetcode_tags.map((tag, index) => (
                      <Button
                        disabled={true}
                        variant="info"
                        className="mx-2 text-white"
                        key={index}
                      >
                        {tag}
                      </Button>
                    ))}
                  </td>
                  <td>
                    <Button
                      disabled={row.leetcode_link == ""}
                      onClick={() => window.open(row.leetcode_link, "_blank")}
                    >
                      {row.leetcode_link_title}
                    </Button>
                  </td>

                  {/* <td>
                
              <Button 
              onClick={()=>{showModal(index)}}>Questions</Button>

              </td> */}
                  <td>
                    <Button
                      variant="success"
                      onClick={() => window.open(row.solution_link, "_blank")}
                    >
                      solution link
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : null}
    </>
  );
}
